import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import SearchForm from './SearchForm';
import { BrowserView, MobileView, isMobile } from 'react-device-detect'

const Header = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const setDay = useCallback((currentDate) => {
    currentDate.setDate(currentDate.getDate() - 1);
    return currentDate.toLocaleDateString('en-us');
  }, []);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div>
      <NavLink to="/today" className="title">
        <img src="/logo.svg" alt="main logo" className='mainLogo' />
      </NavLink>

      <div style={{ float: 'right', display: 'inline-block' }}>
        {
          isAuthenticated ? <> <NavLink to='/profile' className={window.location.pathname.includes('profile') ? 'headerButtons' : 'hbUnselected'}>Profile</NavLink> <div style={{ marginRight: '0.1rem', display: 'inline-block' }} /> <button className='loginButton' onClick={() => logout(
            {
              logoutParams: {
                returnTo: window.location.origin,
                federated: false,
              },
            }
          )}>
            Logout
          </button> </> :
            <button className='loginButton' onClick={() => loginWithRedirect()}>
              Login
            </button>
        }
      </div>
      <MobileView style={{ display: 'inline' }} >
        <center>
          <div style={{ margin: '0rem', marginTop: '2rem' }} />
          <SearchForm />
        </center>
      </MobileView>
      <div style={{ paddingTop: `${isMobile ? '2rem' : '4.375rem'}`, marginBottom: '2rem' }}>
        <NavLink to="/today" className={window.location.pathname.includes('today') || window.location.pathname === '/' ? 'headerButtons' : 'hbUnselected'} onClick={() => {
          localStorage.setItem("index", 14);
          localStorage.setItem("kws", "");
        }}>
          Today
        </NavLink>
        <div className='hbPDiv' />
        <NavLink to="/past" className={window.location.pathname.includes('past') ? 'headerButtons' : 'hbUnselected'} onClick={() => {
          localStorage.setItem("index", 14);
          localStorage.setItem("day", setDay(new Date()));
          localStorage.setItem("kws", "");
          localStorage.setItem("tags", "");
        }}>
          Past
        </NavLink>
        <div className='hbPDiv' />
        <NavLink to="/best" className={window.location.pathname.includes('best') ? 'headerButtons' : 'hbUnselected'} onClick={() => {
          localStorage.setItem("index", 14);
          localStorage.setItem("kws", "");
        }}>
          Best
        </NavLink>
        {
          window.location.pathname.includes('post') ? null : <div style={{ float: 'right', display: 'inline-block' }}>
            <BrowserView style={{ display: 'inline' }} >
              <SearchForm />
            </BrowserView>
            <a href="/post" className='createPostButton'>
              Create Post
            </a>
          </div>
        }
      </div>
    </div >
  )
};
export default Header;
