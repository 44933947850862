import React from 'react';

const NoComments = () => {
    return (
        <p>
            No comments have been found for this post.
        </p>
    );
}

export default NoComments;
