import React, { useCallback, useState, useEffect } from 'react';
import CommentInput from './commentInput';
import NoComments from './noComments';
import Comment from './Comment';
import Story from './Story';
import { getComments } from '../utils/fetchComments';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';

function processReplies(data, kids, useForceUpdate) { // this is retarded
    const t = {};
    const commentArray = [];
    const root = data[0].articleId;

    data.forEach((o) => {
        Object.assign((t[o.id] = t[o.id] || {}), o);
        t[o.parent] = t[o.parent] || {};
        t[o.parent].children = t[o.parent].children || [];
        t[o.parent].children.push(t[o.id]);
    });

    // comment upvotes are disabled for now, this is redundant
    /*const sortChildren = (children) => {
        return children.sort((a, b) => b.score - a.score);
    };*/

    const processComment = (comment) => {
        comment.kids = kids;
        commentArray.push(
            <Comment key={comment.id} props={comment} cb={useForceUpdate} />
        );
        if (comment.children && comment.children.length > 0) {
            // comment.children = sortChildren(comment.children); // Sort the children
            comment.children.forEach(processComment); // Call processComment recursively
        }
    };

    const rootComment = t[root];
    if (rootComment) {
        // rootComment.children = sortChildren(rootComment.children); // Sort the root's children
        rootComment.children.forEach(processComment); // Call processComment for each root's child
    }

    return commentArray;
}
const processComments = (entries, kids, useForceUpdate) => { // is the export even neccessary?
    if (entries.length > 0) {
        return processReplies(entries, kids > 0 ? kids : 0, useForceUpdate);
    }
    return <NoComments />;
};

async function fetchComments(user, sub) {
    return await getComments(user, sub);
}

export const GetComments = ({ props, reset, reload }) => {
    const { user } = useAuth0();
    const { refetch, isLoading, isError, data: { data: comments } = {} } = useQuery(['comments', props.id], () => fetchComments(props.id, user ? user.sub : 'anon'), {
        refetchOnWindowFocus: false,
        enabled: Boolean(props.id),
        cacheTime: 1000 * 300,// 300 seconds, we want to keep this for a short time
    });

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Something went wrong...</div>;

    return (
        <>
            {
                comments && comments.length > 0 ? processComments(comments, props.kids, reset) : null
            }
            {
                comments?.length > Number(localStorage.getItem('cindex')) - 1 ?
                    <>
                        <div className='spacerDiv' />
                        <button onClick={() => { localStorage.setItem("cindex", Number(localStorage.getItem("cindex")) + 15); refetch(); }} className='loginButton'>More</button>
                    </> : null
            }
        </>
    );
}

const DisplayComments = ({ story }) => {
    const [value, setValue] = useState(0);
    const [kids, setKids] = useState(story.kids);
    story.kids = kids;// what the fuck was I on when I wrote this???
    const queryClient = useQueryClient();
    const queryCache = queryClient.getQueryCache();

    const [comments, setComments] = useState([]);

    useEffect(() => {
        const queryEntry = queryCache.find(['comments', story.id]);
        if (queryEntry && queryEntry.state && queryEntry.state.data && queryEntry.state.data.data) {
            setComments(queryEntry.state.data.data);
        }
    }, [queryCache, story.id]);

    const reset = (comment) => {
        const queryEntry = queryCache.find(['comments', story.id]);
        let temp = [];

        if (comment.parent === story.id) { // if the comment is a root comment, add it to the bottom
            temp = [
                ...queryEntry.state.data.data,
                comment
            ];
        }

        else {
            for (let i = 0; i < queryEntry.state.data.data.length; i++) {
                if (comment.parent === queryEntry.state.data.data[i].id) {
                    temp = [
                        ...queryEntry.state.data.data.slice(0, i),
                        comment,
                        ...queryEntry.state.data.data.slice(i)
                    ];
                    break; // minimal sorting
                }
            }
        }

        if (temp.length > 14) {
            localStorage.setItem("cindex", Number(localStorage.getItem("cindex")) + 1);
        }

        setComments(temp); // update the state with the new comments
        setKids(kids => kids + 1);

        queryEntry.state.data.data = temp;
        queryEntry.setState(queryEntry.state);

        setValue(value => value + 1); // force a re-render
    };

    const reload = useCallback(() => {
        setValue(value => value + 1);
    }, [setValue]);

    return (
        <>
            <Story key={story.id} post={story} />
            <CommentInput cIdx={0} kids={story.kids} parent={story.id} articleId={story.id} callback={reset} />
            <div className='spacerDiv' />
            <div className='story' style={{ marginLeft: '0px', paddingLeft: '0px' }}>
                <GetComments props={{ id: story.id, kids: story.kids }} reset={reset} reload={reload} key={value} />
            </div>
        </>
    );
}

export default DisplayComments;
