import React from 'react';
import Story from './Story';
import DisplayComments from './displayComments';
import { useQuery } from '@tanstack/react-query';
import { getStories } from '../utils/apis';
import PageNotFound from './PageNotFound';
import { useAuth0 } from '@auth0/auth0-react';

const fetchStories = async (type, id) => {
	return await getStories(type, id);
};

const ShowStories = ({ type, id }) => {
	const { user } = useAuth0();
	const { refetch, isLoading, isError, data: { data: stories } = {} } = useQuery(['stories', type, id], () => fetchStories(type, user ? user.sub : 'anon'), {
		refetchOnWindowFocus: true,
		cacheTime: 1000 * 60 * 60, // 1 hour
	});

	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (isError) {
		return <p>Oops, something went wrong, please try again later.</p>;
	}

	if (Number(type)) {;
		if (stories == null || stories.length <= 0)
			return <PageNotFound />;
		return <DisplayComments story={stories} />;
	}

	if (stories == null || stories.length <= 0) {
		return <p>No stories found.</p>;
	}

	return (
		<React.Fragment>
			{stories.map((story) => (
				<Story key={story.id} post={story} />
			))}
			{
				stories.length > Number(localStorage.getItem('index')) - 1 ?
					<button className='loginButton' onClick={() => {
						localStorage.setItem("index", Number(localStorage.getItem("index")) + 15);
						refetch();
					}}>
						More
					</button> : null
			}
		</React.Fragment>
	);
};

export default ShowStories;
