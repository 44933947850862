import React from "react";

export default function SearchForm() {
    return (
        <form aria-label="search form" style={{ display: 'inline-block', marginRight: '1rem' }}
            onSubmit={(e) => {
                e.preventDefault();
                localStorage.setItem("kws", e.target.search.value);
                window.location.href = "/search";
            }}>
            <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
                <input
                    id="search"
                    type="search"
                    style={{
                        padding: '0.1rem',
                        paddingLeft: '2rem',
                        width: '100%',
                        borderRadius: '9999px',
                        backgroundColor: '#033335',
                        border: '1px solid #ffffff33',
                        color: '#fff',
                    }}
                    defaultValue={localStorage.getItem("kws")}
                />
                {
                    localStorage.getItem("kws")?.length > 0 ?
                        <button
                            style={{
                                position: 'absolute',
                                right: '0',
                                top: '0',
                                bottom: '0',
                                margin: '0px',
                                padding: '0px',
                                paddingLeft: '0.5rem',
                                paddingRight: '0.5rem',
                                paddingBottom: '0.2rem',
                                border: 'none',
                                color: 'slategray',
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                localStorage.setItem("kws", "");
                                window.location.href = "/";
                            }}
                        >
                            ⨉
                        </button>
                        : null
                }
                <div style={{ position: 'absolute', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg
                        style={{ width: '1rem', height: '1rem', flexShrink: '0', margin: '0.75rem' }}
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            style={{ fill: 'slategray' }}
                            d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm8.707 12.293a.999.999 0 11-1.414 1.414L11.9 13.314a8.019 8.019 0 001.414-1.414l2.393 2.393z"
                        />
                    </svg>
                </div>
            </div>
        </form>
    );
}
