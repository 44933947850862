import { supabase } from './initSupabase';

export const getStories = async (type, id) => {
	if (type === "today") {// ! NOTE supabase rpc is in alpha, needs further testing
		return await supabase.rpc('getstories', { sub: id }).eq('date', new Date().toLocaleDateString("en-US")).order('score', { ascending: false }).order('id', { ascending: false }).limit(Number(localStorage.getItem("index")));
	}

	if (type === "past") {
		const tags = localStorage.getItem("tags");
		if (tags && tags.length > 0) {
			return await supabase.rpc('getstories', { sub: id }).textSearch('tags', `'${tags}'`).order('score', { ascending: false }).order('id', { ascending: false }).limit(Number(localStorage.getItem("index")));
		}

		return await supabase.rpc('getstories', { sub: id }).eq('date', localStorage.getItem("day")).order('score', { ascending: false }).order('id', { ascending: false }).limit(Number(localStorage.getItem("index")));
	}

	if (type === "best") {
		return await supabase.rpc('getstories', { sub: id }).order('score', { ascending: false }).order('id', { ascending: false }).limit(Number(localStorage.getItem("index")));
	}

	if (type === "search") {
		const keywords = localStorage.getItem("kws");
		if (keywords && keywords.length > 0) {
			return await supabase.rpc('getstories', { sub: id }).order('score', { ascending: false }).order('id', { ascending: false }).textSearch('title', `'${keywords}'`);
		}

		return {
			body: [[{}]]
		};
	}

	if (Number(type)) {
		return await supabase.rpc('getstories', { sub: id }).eq('id', type).limit(1).maybeSingle();
	}

	if (type.includes('by')) {
		return await supabase.rpc('getstories', { sub: id }).eq('by', type.slice(type.indexOf("=") + 1, type.length)).order('score', { ascending: false }).order('id', { ascending: false }).limit(Number(localStorage.getItem("index")));
	}

	return {// return empty array for anything that isnt a story page
		body: [[{}]]
	};
}
