import React from 'react';
import { render } from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import history from "./utils/history";
import './styles.css';
import App from './App';
import { Router } from 'react-router-dom';
import Auth0ProviderWithHistory from './auth0_history_redirect';

render(
    <Router history={history}>
        <Auth0ProviderWithHistory>
            <App />
        </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById("root")
);

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
/*
    <Auth0Provider {...providerConfig}>
        <App />
    </Auth0Provider>
*/
