import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from './config';

const Auth0ProviderWithHistory = ({ children }) => {
    const config = getConfig();
    const history = useHistory();

    const onRedirectCallback = (appState) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    const audience = config.audience ? { audience: config.audience } : null;
    return (
        <Auth0Provider
            domain={config.domain}
            clientId={config.clientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: audience.audience,// pain
                scope: 'openid profile email offline offline_access',// TODO check if redundant
            }}
            cacheLocation='localstorage'
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
