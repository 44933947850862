import React from 'react';

const PageNotFound = () => {
  return (
    <p>
      Page Not found. Go <a href="/today" rel="noreferrer" style={{ color: '#f4ae0a' }}>
        home
      </a>.
    </p>
  );
};

export default PageNotFound;
