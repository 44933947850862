import React from "react";
import AppRouter from "./router/AppRouter";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./utils/initQueryClient";

function setDay() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    return currentDate.toLocaleDateString('en-us');
}

const App = () => {
    const { isLoading, error } = useAuth0();

    useEffect(() => {
        if (localStorage.getItem("day") !== setDay()) {
            localStorage.setItem("day", setDay());
        }
    })

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (isLoading) {
        return <></>;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <AppRouter />
        </QueryClientProvider>
    );
};
export default App;
