import React from "react";
import { Button, ButtonGroup } from "reactstrap";

class TagFilter extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { text: '' };
        this.toggle = this.toggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {// load user inputted keywords
        this.setState({ text: localStorage.getItem("tags") });
    }

    toggle(txt) {
        if (this.state.text.includes(txt)) {
            this.setState({ text: this.state.text.replace(`${txt}`, "") });
        }
        else {
            this.setState({ text: txt });
        }
    }

    handleSubmit() {
        localStorage.setItem("tags", this.state.text);
        this.props.cb();
    }

    render() {
        return ( // TODO rewrite this mess
            <>
                <ButtonGroup style={{ marginLeft: '15px' }}>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Office') }} style={{ color: (this.state.text.includes('Office') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Office') ? '#59efbd' : '') }} active={this.state.text.includes('Office')} >Office</Button>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Retail') }} style={{ color: (this.state.text.includes('Retail') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Retail') ? '#59efbd' : '') }} active={this.state.text.includes('Retail')} >Retail</Button>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Industrial') }} style={{ color: (this.state.text.includes('Industrial') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Industrial') ? '#59efbd' : '') }} active={this.state.text.includes('Industrial')} >Industrial</Button>
                </ButtonGroup>
                <div className="bgSpacer" />
                <ButtonGroup style={{ marginLeft: '15px' }}>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Multifamily') }} style={{ color: (this.state.text.includes('Multifamily') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Multifamily') ? '#59efbd' : '') }} active={this.state.text.includes('Multifamily')} >Multifamily</Button>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Hospitality') }} style={{ color: (this.state.text.includes('Hospitality') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Hospitality') ? '#59efbd' : '') }} active={this.state.text.includes('Hospitality')} >Hospitality</Button>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Mixed-Use') }} style={{ color: (this.state.text.includes('Mixed-Use') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Mixed-Use') ? '#59efbd' : '') }} active={this.state.text.includes('Mixed-Use')} >Mixed-Use</Button>
                </ButtonGroup>
                <div className="bgSpacer" />
                <ButtonGroup style={{ marginLeft: '15px' }}>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Special Purpose') }} style={{ color: (this.state.text.includes('Special Purpose') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Special Purpose') ? '#59efbd' : '') }} active={this.state.text.includes('Special Purpose')} >Special Purpose</Button>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Land') }} style={{ color: (this.state.text.includes('Land') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Land') ? '#59efbd' : '') }} active={this.state.text.includes('Land')} >Land</Button>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Healthcare') }} style={{ color: (this.state.text.includes('Healthcare') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Healthcare') ? '#59efbd' : '') }} active={this.state.text.includes('Healthcare')} >Healthcare</Button>
                </ButtonGroup>
                <div className="bgSpacer" />
                <ButtonGroup style={{ marginLeft: '15px' }}>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Self-Storage') }} style={{ color: (this.state.text.includes('Self-Storage') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Self-Storage') ? '#59efbd' : '') }} active={this.state.text.includes('Self-Storage')} >Self-Storage</Button>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('Residential') }} style={{ color: (this.state.text.includes('Residential') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('Residential') ? '#59efbd' : '') }} active={this.state.text.includes('Residential')} >Residential</Button>
                    <Button className="filterBtn" outline onClick={() => { this.toggle('None') }} style={{ color: (this.state.text.includes('None') ? '#022728' : '#fff'), backgroundColor: (this.state.text.includes('None') ? '#59efbd' : '') }} active={this.state.text.includes('None')}>Other</Button>
                </ButtonGroup>
                <div />
                <Button onClick={this.handleSubmit}
                    className="loginButton"
                    style={{ marginLeft: '15px', border: 'none', padding: '0px', textTransform: 'none' }}
                >
                    Search
                </Button>
            </>
        )
    }
}

export default TagFilter;
