import React, { useState } from 'react';
import TagFilter from "./TagFilter";

const DropdownMenu = ({ cb }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="dropdown-container">
            <button onClick={toggleDropdown} className='loginButton'>Filter {isOpen ? <svg style={{
                    flexShrink: '0',
                    fill: '#022728',
                    margin: '0px',
                    padding: '0px',
                }} width="11" height="7" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.664 6.747.336 5.253 5.5.662l5.164 4.591-1.328 1.494L5.5 3.338z" />
                </svg> : <svg style={{
                    flexShrink: '0',
                    fill: '#022728',
                    margin: '0px',
                    padding: '0px',
                    transform: 'rotate(180deg)',
                }} width="11" height="7" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.664 6.747.336 5.253 5.5.662l5.164 4.591-1.328 1.494L5.5 3.338z" />
                </svg>
                }</button>
            <div className={`dropdown-content ${isOpen ? 'open' : ''}`}>
                <p>Filter by tags:</p>
                <TagFilter cb={cb} />
            </div>
        </div>
    );
};

export default DropdownMenu;
