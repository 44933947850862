import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const apiOrigin = process.env.REACT_APP_API_ORIGIN || "http://localhost:3001";

export default function Upvotes({ props }) {
    const [disabledUpvote, setDisabledUpvote] = useState(props.upvsign === '+' ? false : true);
    const [score, setScore] = useState(props.score);
    const [text, setText] = useState(props.upvsign);
    const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, user } = useAuth0();
    const queryClient = useQueryClient();
    const queryCache = queryClient.getQueryCache();

    const handleMutate = useMutation((data) => {
        getAccessTokenSilently().then(token => {
            const options = {
                method: 'POST',
                url: `${apiOrigin}/api/external/upvote`,
                headers:
                {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'cache-control': 'no-cache',
                },
                data
            };
            axios.request(options).then(res => {
            }).catch(err => {
            });

            // update cache:
            let type = window.location.pathname.substring(1, window.location.pathname.length);
            type = type.substring(0, type.indexOf('/') === -1 ? type.length : type.indexOf('/'));// hacky fix but it works
            const queryEntry = queryCache.find(['stories', type, type]);
            const stories = queryEntry.state.data.data;// TODO rewrite this spaghetti

            for (const story of stories) {
                if (story.id === data.id) {
                    story.upvsign = data.score > story.score ? '-' : '+';
                    story.score = data.score;
                }
            }
            queryEntry.setState(stories);
        });
    });

    const handleUpvoteClicked = () => {
        if (isAuthenticated) {
            handleMutate.mutate({
                id: props.id,
                score: disabledUpvote ? score - 1 : score + 1,
                sub: user.sub,
                params: !disabledUpvote
            });

            setDisabledUpvote(!disabledUpvote);// i hate this shit
            setScore(disabledUpvote ? score - 1 : score + 1);
            setText(disabledUpvote ? '+' : '-');
        }
        else {
            loginWithRedirect();
        }
    }

    return (
        <button style={{
            display: 'inline-block',
            width: '4rem',
            maxHeight: '50px',
            padding: '0.625rem 0.5rem',
            margin: '0px',
            marginRight: '1rem',
            borderRadius: '0.375rem',
            border: '1px solid #3C3C3C',
            backgroundImage: 'linear-gradient(to top right, rgba(18, 18, 18, 0.05), rgba(18, 18, 18, 0.2), rgba(18, 18, 18, 0.05))',
            color: '#7F7F7F',
            cursor: 'pointer',
            transition: 'background-color 150ms ease-in-out',
            ':hover': {
                backgroundColor: '#2C2C2C',
            },
        }} onClick={handleUpvoteClicked}>
            {props.upvsign === '+' ?
                <> <svg style={{
                    flexShrink: '0',
                    fill: '#59efbd',
                    marginRight: '0.375rem',
                }} width="11" height="7" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.664 6.747.336 5.253 5.5.662l5.164 4.591-1.328 1.494L5.5 3.338z" />
                </svg> <span style={{
                    flexGrow: '1',
                    textAlign: 'center',
                    fontSize: '0.9rem',
                    fontWeight: '500',
                    color: '#59efbd',
                    fontFamily: 'Lexend'
                }}>{score}</span> </> :
                <><svg style={{
                    flexShrink: '0',
                    fill: '#f74848',
                    marginRight: '0.375rem',
                    transform: 'rotate(180deg)',
                }} width="11" height="7" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.664 6.747.336 5.253 5.5.662l5.164 4.591-1.328 1.494L5.5 3.338z" />
                </svg> <span style={{
                    flexGrow: '1',
                    textAlign: 'center',
                    fontSize: '0.9rem',
                    fontWeight: '500',
                    color: '#f74848',
                    fontFamily: 'Lexend'
                }}>{score}</span> </>
            }
        </button>
    )
}
