import React from "react";
import { supabase } from '../utils/initSupabase';
import { withAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
const apiOrigin = process.env.REACT_APP_API_ORIGIN || "http://localhost:3001";

class CommentInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = { value: '', status: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateKids = this.updateKids.bind(this);
    }

    async updateKids() {
        const kids = this.props.kids + 1;
        return await supabase
            .from('scraper-full-db')
            .update({ kids })
            .eq('id', this.props.pid ? this.props.pid : this.props.parent);
    }

    handleChange(event) { this.setState({ value: event.target.value }); }
    handleSubmit(event) {
        event.preventDefault();
        if (this.props.auth0.isAuthenticated) {
            if (this.state.value.length > 0 && !/^(.)\1+$/.test(this.state.value) && this.state.value !== "invalid comment") {
                const temp = this.state.value;
                this.setState({
                    value: '',
                    status: 'Posting your comment...'
                });
                const { getAccessTokenSilently } = this.props.auth0;
                getAccessTokenSilently()
                    .then(token => {
                        const options = {
                            method: 'POST',
                            url: `${apiOrigin}/api/external/comments`,
                            headers:
                            {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                            data:
                            {
                                value: temp,
                                by: this.props.auth0.user.nickname,
                                pid: this.props.pid ? this.props.pid : this.props.parent,
                                parent: this.props.parent ? this.props.parent : null,
                                cIdx: this.props.parent === this.props.articleId ? 0 : this.props.cIdx + 1,
                                // isProfessional: this.props.auth0.user.isProfessional,
                            }
                        };

                        axios.request(options).then((data) => {
                            this.updateKids();
                            this.setState({
                                value: '',
                                status: ''
                            });
                            this.props.callback({ id: Number(this.props.id) + 1, comment: temp, by: this.props.auth0.user.nickname, pid: this.props.pid ? this.props.pid : this.props.parent, cIdx: this.props.parent === this.props.articleId ? 0 : this.props.cIdx + 1, parent: this.props.parent ? this.props.parent : null, }); // run DOM update function on comment post
                        }).catch((error) => {
                            console.error(error);
                        });
                    })
                    .catch(err => {
                    })
            }
            else {
                this.setState({
                    value: "invalid comment",
                    status: ''
                })
            }
        }
        else
            this.props.auth0.loginWithRedirect();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} style={{ display: 'inline-block', flexDirection: 'column', alignItems: 'center' }}>
                <textarea className="commentBox" value={this.state.value} onChange={this.handleChange} style={{ borderColor: '#FFFFFF33', backgroundColor: '#09383980', color: '#fff', margin: '0px' }} />
                <button className="loginButton" onClick={this.handleSubmit}>Post</button>
                <p className="letterCounter">{this.state.value.length}/128</p>
                <p style={{ margin: '0px', padding: '0px' }}>{this.state.status}</p>
            </form>
        );
    }
}

export default withAuth0(CommentInput);
