import React from 'react';
import Upvotes from '../views/upvoteClass';
import { NavLink } from 'reactstrap';
import Popup from 'reactjs-popup';
import Report from "./Report";

const Story = ({ post }) => {
  return (
    <div style={{
      display: 'inline-flex',
      width: '100%',
      // padding: '0.625rem 0.5rem',
      padding: '1.5rem',
      borderRadius: '0.375rem',
      border: '1px solid #3C3C3C',
      backgroundImage: 'linear-gradient(to top right, rgba(18, 18, 18, 0.05), rgba(18, 18, 18, 0.2), rgba(18, 18, 18, 0.05))',
      color: '#7F7F7F',
      transition: 'background-color 150ms ease-in-out',
      ':hover': {
        backgroundColor: '#2C2C2C',
      },
      marginBottom: '1rem'
    }}>
      <div style={{
        display: 'inline-grid',
        gridTemplateColumns: 'repeat(2, auto)',
        gridTemplateRows: '1'
      }}>
        <Upvotes props={post} />
        <div style={{ padding: '0px', marginLeft: '0rem' }}>
          <a href={`/${post.id}/comments`} rel="noreferrer" className='storyTitle'
          >
            {post.title}
          </a>
          <div style={{
            display: 'block',
            alignItems: 'center',
          }}>
            <div style={{
              fontSize: '0.75rem',
              color: '#718096',
              marginLeft: '0rem',
            }}>
              <a style={{
                fontWeight: '500',
                color: '#59efbd',
                textDecoration: 'none',
                transition: 'color 150ms ease-in-out',
              }} href={`/by=${post.by}`}>
                {post.by}
              </a>{' '}
              · <span style={{
                color: '#718096',
              }}>{post.date}</span> ·{' '}
              <NavLink style={{
                color: '#718096',
                textDecoration: 'none',
                transition: 'color 150ms ease-in-out',
                backgroundColor: 'transparent',
                display: 'inline',
                padding: '0',
                margin: '0',
                cursor: 'pointer',
                border: 'none',
                // border: '1px solid #59efbd',
                // borderRadius: '1rem',
                // paddingLeft: '0.5rem',
                // paddingRight: '0.5rem',
              }} href={`/${post.id}/comments`}>
                {post.kids} Comment{post.kids === 1 ? '' : 's'}
              </NavLink> · <Popup position="bottom left" {...{ contentStyle: { background: '#022728', borderColor: '#FFFFFF33',  width: '400px'  } }} trigger={<button className='popupButton' style={{ textTransform: 'none' }}>Report</button>}
					>
						<Report {...post} />
					</Popup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Story;
