import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CommentInput from "./commentInput";
import { replyStyle } from "../utils/styles";
import Report from "./Report";

const Comment = ({ props, cb }) => {
	const isReply = props.childIndex > 0;

	return (
		<div className={isReply ? "reply" : "comment"} style={(isReply ? { ...replyStyle, marginLeft: `${30 * props.childIndex}px` } : null)}>
			<span className='info-span1' style={{ color: '#718096' }}>
				<a href={`/user?=${props.commentOf}`} target="_blank" rel="noreferrer" style={{ color: '#59efbd' }} >{props.commentOf}</a> · <p style={{ display: 'inline-block', padding: '0px', margin: '0px', color: '#718096' }}>{props.date}</p>
			</span>
			<p style={{ padding: '0px', margin: '0px', fontFamily: 'Lexend' }}>{props.comment}</p>
			<div className="story-info">
				<span className='info-span1'>
					<Popup position="bottom left" {...{ contentStyle: { background: '#022728', borderColor: '#FFFFFF33',  width: '400px'  } }} trigger={<button className='popupButton' style={{ textTransform: 'none' }}>Report</button>}
					>
						<Report {...props} />
					</Popup>
				</span>
				<span style={{ color: '#718096', padding: '0px', margin: '0px' }}>
					·
				</span>
				<span>
					{
						isReply ?
							<Popup position="bottom left" {...{ contentStyle: { background: '#022728', borderColor: '#FFFFFF33' } }} trigger={<button className='popupButton' style={{ textTransform: 'none' }}>Reply</button>} >
								<CommentInput cIdx={props.childIndex} pid={props.articleId} kids={props.kids} parent={props.id} callback={cb} />
							</Popup> : <Popup {...{ contentStyle: { background: '#022728', borderColor: '#FFFFFF33' } }} trigger={<button className='popupButton' style={{ textTransform: 'none' }}>Reply</button>} >
								<CommentInput cIdx={props.childIndex} pid={props.articleId} kids={props.kids} parent={props.id} callback={cb} />
							</Popup>
					}
				</span>
			</div>
			<div className={isReply ? 'replySpacer' : 'lineBreak'} />
		</div >
	);
}

export default Comment;
