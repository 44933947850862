export function setDay() {
    let currentDate = localStorage.getItem("day");
    currentDate = new Date(currentDate);
    currentDate.setDate(currentDate.getDate() - 1);
    currentDate = currentDate.toLocaleDateString('en-us');
    localStorage.setItem("day", currentDate);
}

export function setWeek() {
    let currentDate = localStorage.getItem("day");
    currentDate = new Date(currentDate);
    currentDate.setDate(currentDate.getDate() - 7);
    currentDate = currentDate.toLocaleDateString('en-us');
    localStorage.setItem("day", currentDate);
}

export function setMonth1() {
    let currentDate = localStorage.getItem('day');
    currentDate = new Date(currentDate);
    currentDate.setMonth(currentDate.getMonth() - 1);
    currentDate = currentDate.toLocaleDateString('en-us');
    localStorage.setItem("day", currentDate);
}
