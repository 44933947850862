import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const apiOrigin = process.env.REACT_APP_API_ORIGIN || "http://localhost:3001";

const Report = ({ id, comment }) => {
    const [show, setShow] = React.useState(true);
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    return (
        <>
            <div className={show ? "show" : "hide"} style={{ marginLeft: '1rem' }}>
                <p style={{ marginLeft: 0, paddingLeft: 0 }}>Are you sure you want to report this {comment ? 'comment' : 'story'}?</p>
                <button style={{ marginBottom: '1rem' }} className="psButtonHollow" onClick={() => {
                    if (isAuthenticated) {
                        getAccessTokenSilently().then((token) => {
                            axios.post(`${apiOrigin}/api/external/report`, { id, type: !comment }, { // this is some JS bullshit, don't touch it
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    'cache-control': 'no-cache',
                                },
                            }).then((res) => {
                                console.log(res);
                            }).catch((err) => {
                                console.log(err);
                            });
                        });
                        setShow(false);
                    } else {
                        loginWithRedirect();
                    }
                }} >Yes</button>
                <button className="psButtonHollow">No</button>
            </div>
            <div className={show ? "hide" : "show"}>
                <p style={{ marginLeft: '1rem', paddingLeft: 0 }}>Your report has been submitted. Thank you!</p>
            </div>
        </>
    );
}

export default Report;
