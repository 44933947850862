import React from 'react';
import { useState, useCallback } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Header from '../components/Header';
import PageNotFound from '../components/PageNotFound';
import ShowStories from '../components/ShowStories';
import { setDay, setWeek, setMonth1 } from './dayFilterHelpers';
import DropdownMenu from '../components/DropdownMenu';
import Footer from '../components/Footer';

const About = React.lazy(() => import('../components/About'));
const Contact = React.lazy(() => import('../components/Contact'));
const PostStory = React.lazy(() => import('../components/PostStory'));
const Profile = React.lazy(() => import('../components/UserProfile'));

const AppRouter = () => {
  const [seed, setSeed] = useState(1);
  const reset = useCallback(() => {
    localStorage.setItem("index", 14);
    setSeed(Math.random());
  }, [setSeed]);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" render={() => <Redirect to="/today" />} exact={true} />
        <Route path="/today" render={() => {
          return (
            <>
              <Header />
              <ShowStories type="today" id="today" />
            </>
          );
        }} exact={true} />
        <Route path="/best" render={() => {
          return (
            <>
              <Header />
              <ShowStories type="best" id="best" />
            </>
          );
        }} exact={true} />
        <Route path="/past" render={() => {
          return (
            <>
              <Header />
              <DropdownMenu cb={reset} />
              <p className='dayText'>Not seeing what you like? </p> <p className='dayText2'>Go back a
                <button
                  onClick={() => { setDay(); reset(); }}
                  className='dayBtn'
                >day</button>
                ·
                <button
                  className="dayBtn"
                  onClick={() => { setWeek(); reset(); }}
                >week</button>
                ·
                <button
                  className="dayBtn"
                  onClick={() => { setMonth1(); reset(); }}
                >month</button>
              </p>
              <p className='dateDisplay'>Displaying stories for: {localStorage.getItem("tags").length > 0 ? 'custom filter' : localStorage.getItem("day")}</p>
              <div className='spacerDiv' />
              <ShowStories type='past' id='past' key={seed} />
            </>
          )
        }} exact={true} />
        <Route path="/profile" render={() => {
          localStorage.setItem("cindex", 5);
          return (
            <>
              <Header />
              <React.Suspense fallback={<div>Loading...</div>}>
                <Profile />
              </React.Suspense>
            </>
          );
        }} exact={true} />
        <Route path="/search" render={() => {
          return (
            <>
              <Header />
              <ShowStories type="search" id="search" />
            </>
          );
        }} exact={true} />
        <Route path="/post" render={() => {
          return (
            <>
              <Header />
              <React.Suspense fallback={<div>Loading...</div>}>
                <PostStory />
              </React.Suspense>
            </>
          );
        }} exact={true} />
        <Route path="/by=:type" render={({ match }) => {
          const { type } = match.params;
          localStorage.setItem("index", 29);
          return (
            <>
              <Header />
              <p className='dayText'>
                Showing stories by: {type}
              </p>
              <ShowStories type={`by=${type}`} id={type} />
            </>
          );
        }} />
        <Route path="/:type/comments" render={({ match }) => {
          const { type } = match.params;
          localStorage.setItem("cindex", 14);
          return (
            <>
              <Header />
              <ShowStories type={type} id={type} />
            </>
          );
        }} exact={true} />
        <Route path="/about" render={() => {
          return (
            <>
              <Header />
              <React.Suspense fallback={<div>Loading...</div>}>
                <About />
              </React.Suspense>
            </>
          );
        }} exact={true} />
        <Route path="/contact" render={() => {
          return (
            <>
              <Header />
              <React.Suspense fallback={<div>Loading...</div>}>
                <Contact />
              </React.Suspense>
            </>
          );
        }} exact={true} />
        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
