import React from "react";

const Footer = () => { // TODO clean up this retarded mess
    return (
        <footer style={{ borderTop: "1px solid #2D3748", marginTop: '2rem' }}>
            <div style={{ paddingLeft: "4px", paddingRight: "4px", paddingTop: "8px", paddingBottom: "8px" }}>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "4px", marginTop: '5px', alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ marginLeft: '1rem' }}>
                        <a style={{ display: "inline-block", marginBottom: "8px", marginRight: "0px" }} href="/" aria-label="Cruip">
                            <img src='/logo192.png' alt="CRE Pulse" style={{ width: '15%' }} />
                        </a>
                    </div>
                    <div className="footerTextDiv">
                        <a className="footerText" href="/about">
                            About
                        </a>
                        <a className="footerText" href="#0">
                            Privacy & Terms
                        </a>
                        {
                            /*
                            <a className="footerText" href="#0">
                            FAQs
                            </a>
                            */
                        }
                        <a className="footerText" href="/contact">
                            Get In Touch
                        </a>
                    </div>
                </div>
                {/* Bottom area */}
                <div style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: '1rem' }}>
                    {/* Social links */}
                    <div style={{ display: "inline-flex", marginBottom: "4px", order: 1, marginLeft: "4px", marginRight: "0px", justifyContent: "space-between" }}>
                        <a style={{ margin: '0px', padding: '0px', display: "flex", justifyContent: "center", alignItems: "center", color: "#59efbd", textDecoration: "none", transitionDuration: "150ms", transitionTimingFunction: "ease-in-out" }}
                            href="https://twitter.com/cre_pulse" rel="noreferrer" target="_blank" aria-label="Twitter">
                            <svg style={{ width: "32px", height: "32px", fill: "currentColor" }} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                            </svg>
                        </a>
                        {
                            /*
                            <a style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#59efbd", textDecoration: "none", transitionDuration: "150ms", transitionTimingFunction: "ease-in-out" }} rel="noreferrer" target="_blank" href="#0" aria-label="Github">
                                <svg style={{ width: "32px", height: "32px", fill: "currentColor" }} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                                </svg>
                            </a>
                            */
                        }
                        <a style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#59efbd", textDecoration: "none", transitionDuration: "150ms", transitionTimingFunction: "ease-in-out" }} rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/cre-pulse" aria-label="LinkedIn">
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "18px", height: "18px", fill: "currentColor" }} viewBox="0 0 24 24">
                                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                            </svg>
                        </a>
                    </div>

                    <div style={{ fontSize: "small", fontWeight: "medium", order: 0 }}>
                        <p style={{ color: "#667C8A", marginBottom: "0px" }}>
                            © {new Date().getFullYear()} CRE Pulse. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
